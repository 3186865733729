import React from 'react';
import { useCatalogTranslation } from '@lib/hooks/use-catalog-translations';
import { FormatMoneyAmount } from '@lib/utils/helpers/get-formated-price';
import { useCustomerCompany } from '@lib/hooks/use-customer-company';

const TermsView = ({
  paymentTerms,
  franco,
}: {
  paymentTerms: string;
  franco: number;
}) => {
  const { ct } = useCatalogTranslation('homepage');
  const { company } = useCustomerCompany();

  const elements = [
    {
      title: ct('AgentCard.paymentMethods', 'Méthodes de paiement'),
      content: paymentTerms,
    },
    {
      title: ct('AgentCard.businessTerms', 'Conditions commerciales'),
      content: ct('AgentCard.usualDiscounts', 'Remises habituelles'),
    },
    franco > 0 && {
      title: ct('AgentCard.franco', 'Livraison gratuite à partir de'),
      content: FormatMoneyAmount(franco, company.paymentCurrencyCode),
    },
  ].filter(Boolean);

  const gridClass =
    elements.length === 3
      ? 'grid grid-cols-1 sm:grid-cols-2'
      : 'grid grid-cols-1';

  return (
    <div className={`gap-4 py-4  ${gridClass}`}>
      {elements.map((element, index) => (
        <div key={index} className="flex flex-col gap-2">
          <h3 className="text-sm font-semibold">{element.title}</h3>
          <span className="whitespace-nowrap overflow-hidden text-ellipsis rounded-[36px] border border-grey2-20 bg-white  h-7 px-2 text-grey-60 w-fit max-w-full">
            {element.content}
          </span>
        </div>
      ))}
      {elements.length === 3 && (
        <div className="h-[1px] bg-grey2-10 w-full col-span-2"></div>
      )}
    </div>
  );
};

export default TermsView;
