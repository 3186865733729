import React, { useEffect } from 'react';
import Image from 'next/image';
import clsx from 'clsx';
import { FormatMoneyAmount } from '../../../../lib/utils/helpers/get-formated-price';
import { ICartItem } from '../../../../lib/models/view-models/cart';
import { useCatalogTranslation } from '../../../../lib/hooks/use-catalog-translations';
import SplitLine from '../../../common/components/split-line';
import { useCustomerCompany } from '@lib/hooks/use-customer-company';

type ExpressBuyFilledStateType = {
  items: ICartItem[];
  totalHT: number;
};
const ExpressBuyFilledState = ({
  items,
  totalHT,
}: ExpressBuyFilledStateType) => {
  const [refs, setRefs] = React.useState(null);
  const [articles, setArticles] = React.useState(null);
  const [imgs, setImgs] = React.useState<{ src: string; alt: string }[]>([]);
  const { ct } = useCatalogTranslation(['homepage', 'common']);
  const { company } = useCustomerCompany();

  useEffect(() => {
    setRefs(items.length);
    setArticles(
      items.reduce((acc, item) => {
        if (item.displayUnit) {
          return acc + 1;
        }
        return acc + item.quantity;
      }, 0),
    );
    setImgs(
      items
        .map((item) => ({ src: item.thumbnail, alt: item.title }))
        .slice(0, 3),
    );
  }, [items]);

  return (
    <div className="flex flex-col gap-3">
      <div className="relative w-full h-16 flex">
        {imgs.map((img, index) => {
          return (
            <div
              key={index}
              className={clsx(
                'relative w-16 h-16 inset-0 shadow-xs rounded-xl border border-grey2-10 opacity-100',
                {
                  '-translate-x-5 group-hover:border-0': index === 1,
                },
                {
                  '-translate-x-10 group-hover:border-0': index === 2,
                },
              )}
            >
              <Image
                src={img.src}
                alt={img.alt}
                width={64}
                height={64}
                className={clsx(
                  'object-contain shadow-xs rounded-xl border border-grey2-10 transition-opacity duration-700',
                  {
                    'opacity-100 group-hover:opacity-0': index > 0,
                  },
                )}
              />
              {!imgs[index + 1] && (
                <div
                  className={clsx(
                    'absolute opacity-100 top-0 h-6 min-w-6 left-full transition-transform duration-700 -translate-y-1/2 -translate-x-2  text-white bg-seller rounded-full flex justify-center items-center p-2',
                    {
                      'group-hover:-translate-x-24': index === 2,
                      'group-hover:-translate-x-14': index === 1,
                    },
                  )}
                >
                  {items.reduce((acc, item) => {
                    if (item.displayUnit) {
                      return acc + 1;
                    }
                    return acc + item.quantity;
                  }, 0)}
                </div>
              )}
            </div>
          );
        })}
      </div>
      <div>
        <h2 className="font-header text-xl leading-6 text-grey2-90">
          <SplitLine
            lines={ct(
              'ExpressBuyCard.filledState',
              'Vous avez une \ncommande ouverte !',
            )}
          />
        </h2>
        <div className="text-base font-normal text-grey2-90">
          {refs} {ct('OrderOrCart.refs', 'refs', { count: refs, ns: 'common' })}{' '}
          {articles}{' '}
          {ct('OrderOrCart.articles', {
            count: articles,
            ns: 'common',
          })}
        </div>
        <div className="text-base font-semibold text-grey2-90">
          {FormatMoneyAmount(totalHT, company.paymentCurrencyCode)}
        </div>
      </div>
    </div>
  );
};

export default ExpressBuyFilledState;
